<template>
	<div class="page container">
		<v2-back-btn :route="`${this.getCourseRoute}/students`" />
		<div class="page__info" v-if="getNameString">
			<div class="page__info--title">
				{{ getNameString }}
			</div>
		</div>

		<list-view
			v-if="getStudent"
			:list="getList"
		/>
	</div>
</template>

<script>
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';
	import ListView      from '@/components/ui/views/listView/ListView';
	import api           from '@/services/api';
	import dayjs         from 'dayjs';
	import relativeTime  from 'dayjs/plugin/relativeTime';
	import routeParams   from '@/mixins/routeParams';

	dayjs.extend(relativeTime);

	export default {
		layout: 'v2default',
		metaInfo () {
			if (!this.getNameString) {
				return false;
			}
			return {
				title: this.getNameString
			};
		},
		components: {
			V2BackBtn,
			ListView
		},
		mixins: [routeParams],
		data: () => ({
			student: undefined
		}),
		computed: {
			getBreadcrumbRoute () {
				if (!this.getStudentId ||
						!this.getCourseId) {
					return false;
				}
				return `/admin/courses/${this.getCourseId}/students/`;
			},
			getStudent () {
				if (!this.student ||
						!this.student.id) {
					return false;
				}
				return this.student;
			},
			getNameString (student) {
				if (!this.getStudent) {
					return false;
				}
				return `${this.getStudent.firstName} ${this.getStudent.lastName}`;
			},
			getList () {
				if (!this.getStudent) {
					return false;
				}
				return [
					{
						text: `Preferred bidding system: ${this.student.biddingSystem}`
					},
					{
						text: `Experience level: ${this.student.experienceLevel}`
					}
				];
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: 'Course'
					},
					{
						path: `${this.getCourseRoute}/students`,
						text: 'Students'
					},
					{
						path: `/admin/users/${this.getStudentId}`,
						text: this.getNameString
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setStudent();
		},
		methods: {
			async setStudent () {
				const student = await api.admin.getUserById({
					userId: this.getStudentId
				});
				if (!student) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.student = student;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
